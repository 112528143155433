const colors = {
  primary: '#5E2EBA',
  primaryLight: '#693dbf',
  primaryHover: '#40139E',
  primaryText: '#1E1E20',
  smokeText: '#7e7e7e',
  smokeTextLight: '#a5a5a5',
  smokeLineLight: '#F1F1F1',
  smokeLine: '#EAEAEB',
  disabled: '#C4C4C4',
  black: '#000000',
  white: '#ffffff',
  whiteAlternatif: '#F5F5F5',
  redPrimary: '#E73C3C',
  redLight: '#FFEDED',
  lightGrey: '#F8F8F8',
  netralGrey: '#D5D5D5',
  green: '#5CBA60',
  greenLight: '#EDFFED',
  blue: '#0E98D2',
  blueLight: '#ECF9FF',
  yellow: '#EBB630',
  yellowLight: '#FFF4C0',
}

export default colors
