import Loading from '@/components/Loading'
import { Fragment, Suspense } from 'react'
import { LayoutProps } from './types'
import { Helmet } from 'react-helmet'

const Layout = ({ Container, title, useLayout }: LayoutProps) => {
  return (
    <Fragment>
      <Helmet>
        <title>{`Pook Pow - ${title}`}</title>
      </Helmet>
      <Suspense fallback={<Loading />}>
        {useLayout ? (
          <div>
            <Container />
          </div>
        ) : (
          <Container />
        )}
      </Suspense>
    </Fragment>
  )
}

export default Layout
