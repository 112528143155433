import { globalCss } from '@stitches/react'

import colors from './colors'
import fontWeight from './fontWeight'

const globalStyles = globalCss({
  '@import':
    'url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap")',
  '*': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  },
  html: {
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: fontWeight.regular,
    color: colors.primaryText,
  },
  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
})

export default globalStyles
