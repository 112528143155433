import { Fragment, lazy } from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import routes from '@/routes'
import Layout from '@/views/layouts'
import globalStyles from './styled/global'

const Page404 = lazy(() => import('@/views/errors/Page404'))

const App = () => {
  globalStyles()

  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          {routes.map(({ path, container, title, useLayout }, i) => (
            <Route
              key={i}
              path={path}
              element={
                <Layout
                  Container={container}
                  title={title}
                  useLayout={useLayout}
                />
              }
            />
          ))}
          <Route
            path="*"
            element={
              <Layout Container={Page404} title={'Page Not Found'} useLayout />
            }
          />
        </Routes>
      </BrowserRouter>
    </Fragment>
  )
}
export default App
